import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    chartData: Object,
  };

  async connect() {
    // This defers loading Plotly until we actually have it on a page so that it is
    // not loaded everywhere
    await import("plotly.js-dist-min");

    this.createChart();
  }

  createChart() {
    const data = [
      {
        type: "sankey",
        textfont: {
          size: 14,
          weight: 100,
        },
        node: {
          pad: 15,
          thickness: 30,
          line: {
            color: "black",
            width: 0.5,
          },
          align: "left",
          color: this.chartDataValue.colors,
          label: this.chartDataValue.labels,
        },
        link: this.chartDataValue.edges,
      },
    ];

    Plotly.newPlot(this.element, data);
  }
}
