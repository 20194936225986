import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popup"];

  connect() {
    this.hidePopup();
  }

  showPopup() {
    this.popupTarget.classList.remove("hidden");
    this.popupTarget.style.top = `${
      this.element.getBoundingClientRect().bottom + window.scrollY
    }px`;
    this.popupTarget.style.left = `${
      this.element.getBoundingClientRect().left
    }px`;
  }

  hidePopup() {
    this.popupTarget.classList.add("hidden");
  }
}
