import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row", "count"];

  connect() {
    this.showAllRows();
  }

  showAllRows() {
    this.filterRows(() => true);
  }

  updateVisibleCount() {
    const visibleRows = this.rowTargets.filter(
      (row) =>
        !row.classList.contains("hidden") &&
        row.hasAttribute("data-sample-site")
    );

    this.countTarget.textContent = `Visible rows: ${visibleRows.length}`;
  }

  filterByKeys(event: Event) {
    const query = (event.target as HTMLSelectElement).value;

    if (query === "all") {
      return this.showAllRows();
    }

    this.filterRows((row) => {
      const keys = row.dataset.nodeKeys?.toLowerCase().split(",") || [];
      return keys.includes(query);
    });
  }

  // Show or hide rows based on a filter callback
  filterRows(callback: (row: HTMLElement) => boolean) {
    this.rowTargets.forEach((row) => {
      if (callback(row)) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });

    this.updateVisibleCount();
  }
}
